.icon.svg-account-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-account-bg {
    background: url("compiled/sprites.svg#account-view") no-repeat;
}

.icon.svg-add-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-add-bg {
    background: url("compiled/sprites.svg#add-view") no-repeat;
}

.icon.svg-arrow-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-bg {
    background: url("compiled/sprites.svg#arrow-view") no-repeat;
}

.icon.svg-arrow-block-left-dims {
    width: 30px;
    height: 30px;
}

.icon.svg-arrow-block-left-bg {
    background: url("compiled/sprites.svg#arrow-block-left-view") no-repeat;
}

.icon.svg-arrow-block-right-dims {
    width: 30px;
    height: 30px;
}

.icon.svg-arrow-block-right-bg {
    background: url("compiled/sprites.svg#arrow-block-right-view") no-repeat;
}

.icon.svg-arrow-down-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-down-bg {
    background: url("compiled/sprites.svg#arrow-down-view") no-repeat;
}

.icon.svg-arrow-first-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-first-bg {
    background: url("compiled/sprites.svg#arrow-first-view") no-repeat;
}

.icon.svg-arrow-last-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-last-bg {
    background: url("compiled/sprites.svg#arrow-last-view") no-repeat;
}

.icon.svg-arrow-up-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-up-bg {
    background: url("compiled/sprites.svg#arrow-up-view") no-repeat;
}

.icon.svg-back-to-top-icon-dims {
    width: 36px;
    height: 36px;
}

.icon.svg-back-to-top-icon-bg {
    background: url("compiled/sprites.svg#back-to-top-icon-view") no-repeat;
}

.icon.svg-blog-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-blog-bg {
    background: url("compiled/sprites.svg#blog-view") no-repeat;
}

.icon.svg-cart-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-cart-bg {
    background: url("compiled/sprites.svg#cart-view") no-repeat;
}

.icon.svg-cc-amex-dims {
    width: 56px;
    height: 40px;
}

.icon.svg-cc-amex-bg {
    background: url("compiled/sprites.svg#cc-amex-view") no-repeat;
}

.icon.svg-cc-discover-dims {
    width: 56px;
    height: 40px;
}

.icon.svg-cc-discover-bg {
    background: url("compiled/sprites.svg#cc-discover-view") no-repeat;
}

.icon.svg-cc-master-dims {
    width: 56px;
    height: 40px;
}

.icon.svg-cc-master-bg {
    background: url("compiled/sprites.svg#cc-master-view") no-repeat;
}

.icon.svg-cc-visa-dims {
    width: 56px;
    height: 40px;
}

.icon.svg-cc-visa-bg {
    background: url("compiled/sprites.svg#cc-visa-view") no-repeat;
}

.icon.svg-checkbox-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-checkbox-bg {
    background: url("compiled/sprites.svg#checkbox-view") no-repeat;
}

.icon.svg-checkbox-selected-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-checkbox-selected-bg {
    background: url("compiled/sprites.svg#checkbox-selected-view") no-repeat;
}

.icon.svg-checkmark-dims {
    width: 12px;
    height: 12px;
}

.icon.svg-checkmark-bg {
    background: url("compiled/sprites.svg#checkmark-view") no-repeat;
}

.icon.svg-close-icon-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-close-icon-bg {
    background: url("compiled/sprites.svg#close-icon-view") no-repeat;
}

.icon.svg-close-white-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-close-white-bg {
    background: url("compiled/sprites.svg#close-white-view") no-repeat;
}

.icon.svg-decrease-dims {
    width: 15px;
    height: 15px;
}

.icon.svg-decrease-bg {
    background: url("compiled/sprites.svg#decrease-view") no-repeat;
}

.icon.svg-email-icon-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-email-icon-bg {
    background: url("compiled/sprites.svg#email-icon-view") no-repeat;
}

.icon.svg-error-icon-dims {
    width: 19px;
    height: 18px;
}

.icon.svg-error-icon-bg {
    background: url("compiled/sprites.svg#error-icon-view") no-repeat;
}

.icon.svg-facebook-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-facebook-bg {
    background: url("compiled/sprites.svg#facebook-view") no-repeat;
}

.icon.svg-google-plus-dims {
    width: 34px;
    height: 20px;
}

.icon.svg-google-plus-bg {
    background: url("compiled/sprites.svg#google-plus-view") no-repeat;
}

.icon.svg-gozero-dims {
    width: 300px;
    height: 200px;
}

.icon.svg-gozero-bg {
    background: url("compiled/sprites.svg#gozero-view") no-repeat;
}

.icon.svg-help-icon-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-help-icon-bg {
    background: url("compiled/sprites.svg#help-icon-view") no-repeat;
}

.icon.svg-increase-dims {
    width: 17px;
    height: 17px;
}

.icon.svg-increase-bg {
    background: url("compiled/sprites.svg#increase-view") no-repeat;
}

.icon.svg-instagram-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-instagram-bg {
    background: url("compiled/sprites.svg#instagram-view") no-repeat;
}

.icon.svg-logo-dims {
    width: 302px;
    height: 65px;
}

.icon.svg-logo-bg {
    background: url("compiled/sprites.svg#logo-view") no-repeat;
}

.icon.svg-logo-mobile-dims {
    width: 142px;
    height: 30px;
}

.icon.svg-logo-mobile-bg {
    background: url("compiled/sprites.svg#logo-mobile-view") no-repeat;
}

.icon.svg-logo-tablet-dims {
    width: 195px;
    height: 42px;
}

.icon.svg-logo-tablet-bg {
    background: url("compiled/sprites.svg#logo-tablet-view") no-repeat;
}

.icon.svg-mobile-menu-dims {
    width: 22px;
    height: 22px;
}

.icon.svg-mobile-menu-bg {
    background: url("compiled/sprites.svg#mobile-menu-view") no-repeat;
}

.icon.svg-nav-addresses-dims {
    width: 21.1px;
    height: 21.27px;
}

.icon.svg-nav-addresses-bg {
    background: url("compiled/sprites.svg#nav-addresses-view") no-repeat;
}

.icon.svg-nav-cc-dims {
    width: 22px;
    height: 14px;
}

.icon.svg-nav-cc-bg {
    background: url("compiled/sprites.svg#nav-cc-view") no-repeat;
}

.icon.svg-nav-orders-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-nav-orders-bg {
    background: url("compiled/sprites.svg#nav-orders-view") no-repeat;
}

.icon.svg-nav-pay-dims {
    width: 18px;
    height: 23px;
}

.icon.svg-nav-pay-bg {
    background: url("compiled/sprites.svg#nav-pay-view") no-repeat;
}

.icon.svg-nav-prefs-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-nav-prefs-bg {
    background: url("compiled/sprites.svg#nav-prefs-view") no-repeat;
}

.icon.svg-nav-statements-dims {
    width: 20px;
    height: 23px;
}

.icon.svg-nav-statements-bg {
    background: url("compiled/sprites.svg#nav-statements-view") no-repeat;
}

.icon.svg-paypal-dims {
    width: 82px;
    height: 40px;
}

.icon.svg-paypal-bg {
    background: url("compiled/sprites.svg#paypal-view") no-repeat;
}

.icon.svg-pinterest-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-pinterest-bg {
    background: url("compiled/sprites.svg#pinterest-view") no-repeat;
}

.icon.svg-play-button-dims {
    width: 60px;
    height: 60px;
}

.icon.svg-play-button-bg {
    background: url("compiled/sprites.svg#play-button-view") no-repeat;
}

.icon.svg-qv-icon-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-qv-icon-bg {
    background: url("compiled/sprites.svg#qv-icon-view") no-repeat;
}

.icon.svg-radio-selected-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-radio-selected-bg {
    background: url("compiled/sprites.svg#radio-selected-view") no-repeat;
}

.icon.svg-ratings-empty-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-ratings-empty-bg {
    background: url("compiled/sprites.svg#ratings-empty-view") no-repeat;
}

.icon.svg-ratings-full-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-ratings-full-bg {
    background: url("compiled/sprites.svg#ratings-full-view") no-repeat;
}

.icon.svg-ratings-half-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-ratings-half-bg {
    background: url("compiled/sprites.svg#ratings-half-view") no-repeat;
}

.icon.svg-remove-icon-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-remove-icon-bg {
    background: url("compiled/sprites.svg#remove-icon-view") no-repeat;
}

.icon.svg-search-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-search-bg {
    background: url("compiled/sprites.svg#search-view") no-repeat;
}

.icon.svg-secure-dims {
    width: 16px;
    height: 20px;
}

.icon.svg-secure-bg {
    background: url("compiled/sprites.svg#secure-view") no-repeat;
}

.icon.svg-select-arrow-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-select-arrow-bg {
    background: url("compiled/sprites.svg#select-arrow-view") no-repeat;
}

.icon.svg-signin-mobile-icon-dims {
    width: 27px;
    height: 21px;
}

.icon.svg-signin-mobile-icon-bg {
    background: url("compiled/sprites.svg#signin-mobile-icon-view") no-repeat;
}

.icon.svg-store-card-dims {
    width: 70px;
    height: 43px;
}

.icon.svg-store-card-bg {
    background: url("compiled/sprites.svg#store-card-view") no-repeat;
}

.icon.svg-tooltip-icon-dims {
    width: 16px;
    height: 16px;
}

.icon.svg-tooltip-icon-bg {
    background: url("compiled/sprites.svg#tooltip-icon-view") no-repeat;
}

.icon.svg-tooltip-info-white-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-tooltip-info-white-bg {
    background: url("compiled/sprites.svg#tooltip-info-white-view") no-repeat;
}

.icon.svg-twitter-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-twitter-bg {
    background: url("compiled/sprites.svg#twitter-view") no-repeat;
}

.icon.svg-warning-icon-dims {
    width: 46px;
    height: 41px;
}

.icon.svg-warning-icon-bg {
    background: url("compiled/sprites.svg#warning-icon-view") no-repeat;
}

.icon.svg-youtube-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-youtube-bg {
    background: url("compiled/sprites.svg#youtube-view") no-repeat;
}

